import { useState, useEffect } from "react";

function useTimeAgo(createdDate: string): string | null {
  const [timeAgo, setTimeAgo] = useState<string | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const givenDate = new Date(createdDate);
      const currentTime = new Date();
      const timeDifference = currentTime.getTime() - givenDate.getTime();
      const calculatedTimeAgo = calculateTimeAgo(timeDifference);
      setTimeAgo(calculatedTimeAgo);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [createdDate]);

  function calculateTimeAgo(timeDifference: number): string | null {
    const seconds = Math.floor((timeDifference / 1000) % 60);
    const minutes = Math.floor((timeDifference / 1000 / 60) % 60);
    const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (days > 0) {
      return `${days} days ago`;
    } else if (hours > 0) {
      return `${hours} hours ago`;
    } else if (minutes > 0) {
      return `${minutes} minutes ago`;
    } else if (seconds > 0) {
      return `${seconds} seconds ago`;
    } else {
      return null;
    }
  }

  return timeAgo;
}

export default useTimeAgo;
